<template>
    <div class="h-100 w-100" @touchmove.prevent.stop="dragUpdate">
        <no-items-modal
            v-if="!itemsAvailable"
            :language="$i18n.locale"
            :unit="$route.name"
        />
        <template v-else>
            <div
                v-if="refsAvailable && showSvgLines"
                class="pointer-events-none h-100 w-100"
            >
                <template v-for="item in items.filter(x => svgObjects[x.id])">
                    <svg-drawer
                        v-for="zone in svgObjects[item.id].zones"
                        :key="
                            'zone' +
                                item.id +
                                positions[item.id].x +
                                positions[item.id].y +
                                zone
                        "
                        class="svg-line"
                        :start="{
                            x: positions[item.id].x + 50,
                            y: positions[item.id].y + 50,
                        }"
                        :end="{
                            x: dropZoneConnector[zone].x,
                            y: dropZoneConnector[zone].y,
                        }"
                    />
                </template>
            </div>
            <canvas
                id="canvas-to-render-to"
                ref="matterCanvas"
                style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 2"
            ></canvas>
            <div
                v-if="refsAvailable"
                ref="loadoutContainer"
                class="collection"
                style="width: 100vw; height: 100vh; position: absolute; top: 0"
            >
                <div
                    v-for="item in items"
                    :key="'drag_item_' + item.id"
                    :ref="`item_${item.id}`"
                    class="item"
                    :class="[
                        item.thumbnailUrl ? 'circle-image' : '',
                        !(
                            zoneOneItems.includes(item.id) ||
                            zoneTwoItems.includes(item.id) ||
                            zoneThreeItems.includes(item.id)
                        )
                            ? 'disabled'
                            : '',
                    ]"
                    :style="getItemStyle(item)"
                    @touchstart.prevent.stop="dragStart(item, $event)"
                >
                    <img
                        v-if="item.thumbnailUrl"
                        class="pointer-events-none"
                        :src="item.thumbnailUrl"
                        width="100px"
                        height="100px"
                    />
                </div>
            </div>
            <div ref="non-interactive-area" class="non-interactive-area"></div>
            <div ref="interactive-area" class="interactive-area"></div>
            <div
                v-show="zoneOneItems.length >= 1"
                id="drop-zone-1"
                ref="drop-zone-1"
                class="drop-zone"
                :class="isEnabled('drop-zone-1') ? 'enabled' : 'disabled'"
            >
                <slot name="dropZone1" />
            </div>
            <div
                v-show="zoneTwoItems.length >= 1"
                id="drop-zone-2"
                ref="drop-zone-2"
                class="drop-zone"
                :class="isEnabled('drop-zone-2') ? 'enabled' : 'disabled'"
            >
                <slot name="dropZone2" />
            </div>
            <div
                v-show="zoneThreeItems.length >= 1"
                id="drop-zone-3"
                ref="drop-zone-3"
                class="drop-zone"
                :class="isEnabled('drop-zone-3') ? 'enabled' : 'disabled'"
            >
                <slot name="dropZone3" />
            </div>
        </template>
    </div>
</template>

<script>
import { findTouchId } from '@/utils/touch'
import SvgDrawer from '@/components/global/SvgDrawer'
import anime from 'animejs/lib/anime.es.js'
import * as Matter from 'matter-js'
import NoItemsModal from '@/components/global/NoItemsModal.vue'
import LoginTimer from '@/components/global/LoginTimer.vue'

export default {
    name: 'FowiDragAndDropZone',
    components: { LoginTimer, NoItemsModal, SvgDrawer },
    props: {
        items: {
            type: [Array, null],
            required: true,
        },
        zoneOneItems: {
            type: Array,
            default: () => [],
        },
        zoneTwoItems: {
            type: Array,
            default: () => [],
        },
        zoneThreeItems: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            itemsAvailable: false,
            refsAvailable: false,
            showSvgLines: false,
            animationDelayPerObject: 30,
            isDragging: false,
            positions: {},
            animatePositions: {},
            afterStartPositions: {},
            dropZoneConnector: {},
            draggedObjects: {},
            svgObjects: {},
            draggedItemDomObject: null,
            matter: {
                engine: null,
                render: null,
                nowDraggingItem: null,
            },
        }
    },
    watch: {
        animatePositions: {
            deep: true,
            handler(val) {
                this.animateToPosition(val)
            },
        },
    },
    async mounted() {
        this.itemsAvailable = this.items?.length > 0
        if (this.itemsAvailable) {
            this.$nextTick(() => {
                this.setDropZonePosition()
                //this.initMatter()
                this.setItemStartPosition()
                this.setItemEndPosition()
                this.animatedStart()
                // setTimeout(() => {
                this.showSvgLines = true
                //}, (this.items.length + 1) * this.animationDelayPerObject + 100)
                this.refsAvailable = true
                this.initMatter()
            })
            this.items.forEach(item => {
                this.thumbnailUrl(item)
            })
        }
    },
    beforeDestroy() {
        if (this.itemsAvailable) {
            Matter.Engine.clear(this.matter.engine)
            Matter.World.clear(this.matter.engine.world)
        }
    },
    methods: {
        initMatter() {
            const Engine = Matter.Engine,
                Render = Matter.Render,
                Runner = Matter.Runner,
                Bodies = Matter.Bodies,
                Composite = Matter.Composite,
                Mouse = Matter.Mouse,
                MouseConstraint = Matter.MouseConstraint
            const engine = Engine.create()

            this.matter.engine = engine

            const render = Render.create({
                element: document.body,
                engine: engine,
                canvas: this.$refs.matterCanvas,
                options: {
                    wireframes: false,
                    background: 'transparent',
                    wireframeBackground: 'transparent',
                    width: 1920,
                    height: 1080,
                },
            })

            this.matter.render = render

            this.$nextTick(() => {
                const ground = Bodies.rectangle(
                    0,
                    this.$refs.loadoutContainer.getBoundingClientRect().height,
                    this.$refs.loadoutContainer.getBoundingClientRect().width *
                        2,
                    50,
                    {
                        isStatic: true,
                        render: {
                            fillStyle: 'transparent',
                        },
                    },
                )
                const left = Bodies.rectangle(
                    0,
                    0,
                    100,
                    this.$refs.loadoutContainer.getBoundingClientRect().height *
                        2,
                    {
                        isStatic: true,
                        render: {
                            fillStyle: 'transparent',
                        },
                    },
                )
                const right = Bodies.rectangle(
                    this.$refs.loadoutContainer.getBoundingClientRect().width,
                    0,
                    100,
                    this.$refs.loadoutContainer.getBoundingClientRect().height *
                        2,
                    {
                        isStatic: true,
                        render: {
                            fillStyle: 'transparent',
                        },
                    },
                )
                const top = Bodies.rectangle(
                    0,
                    0,
                    this.$refs.loadoutContainer.getBoundingClientRect().width *
                        2,
                    100,
                    {
                        isStatic: true,
                        render: {
                            fillStyle: 'transparent',
                        },
                    },
                )
                Composite.add(engine.world, [ground, left, right, top])
            })
            // add all of the bodies to the world
            const mouse = Mouse.create(render.canvas),
                mouseConstraint = MouseConstraint.create(engine, {
                    mouse: mouse,
                    constraint: {
                        stiffness: 0.1,
                        damping: 1,
                        render: {
                            visible: false,
                        },
                    },
                })

            Composite.add(engine.world, mouseConstraint)
            engine.world.gravity.y = 0

            Matter.Events.on(mouseConstraint, 'startdrag', e => {
                console.log('start')
                this.matter.nowDraggingItem = e.body.label
                e.body.isSensor = true
                this.$refs[`item_${e.body.label}`][0].style.zIndex = 100
                this.isDragging = true
                console.log('start end')
            })
            Matter.Events.on(mouseConstraint, 'enddrag', e => {
                console.log('end')
                let dropzone

                const element = this.$refs[
                    `item_${e.body.label}`
                ][0].getBoundingClientRect()
                const zoneOne = this.$refs[
                    'drop-zone-1'
                ].getBoundingClientRect()
                const zoneTwo = this.$refs[
                    'drop-zone-2'
                ].getBoundingClientRect()
                const zoneThree = this.$refs[
                    'drop-zone-3'
                ].getBoundingClientRect()
                const leftBorder = zoneOne.left
                const rightBorder = zoneOne.left + zoneOne.height
                if (element.left >= leftBorder && element.left <= rightBorder) {
                    if (
                        element.top >= zoneOne.top &&
                        element.top <= zoneOne.top + zoneOne.height
                    ) {
                        dropzone = this.$refs['drop-zone-1']
                    } else if (
                        element.top >= zoneTwo.top &&
                        element.top <= zoneTwo.top + zoneTwo.height
                    ) {
                        dropzone = this.$refs['drop-zone-2']
                    } else if (
                        element.top >= zoneThree.top &&
                        element.top <= zoneThree.top + zoneThree.height
                    ) {
                        dropzone = this.$refs['drop-zone-3']
                    }
                }
                if (dropzone?.classList.contains('drop-zone')) {
                    if (!dropzone?.classList.contains('disabled')) {
                        const item = this.items.find(i => i.id === e.body.label)
                        this.$emit('dropZone', {
                            zone: dropzone.id,
                            item: item,
                        })
                    }
                    Matter.Body.setPosition(e.body, {
                        x: e.mouse.mousedownPosition.x - 50,
                        y: e.mouse.mousedownPosition.y - 50,
                    })
                }
                this.isDragging = false
                e.body.isSensor = false
                console.log('end end')
            })

            // run the renderer
            Render.run(render)

            // create runner
            const runner = Runner.create()

            // run the engine
            Runner.run(runner, engine)
            window.requestAnimationFrame(() => this.stickMatterToDom())
        },
        animateToPosition(val) {
            const key = Object.keys(val)[0]
            if (!this.animatePositions[key]) {
                return
            }
            const obj = { x: this.positions[key].x, y: this.positions[key].y }
            anime({
                targets: obj,
                x: this.animatePositions[key].x,
                y: this.animatePositions[key].y,
                duration: 500,
                easing: 'easeOutCubic',
                update: () => {
                    this.$set(this.positions, key, obj)
                },
                complete: () => {
                    this.$delete(this.animatePositions, key)
                },
            })
        },
        stickMatterToDom() {
            Matter.Composite.allBodies(this.matter.engine.world).forEach(
                item => {
                    if (
                        this.$refs[`item_${item.label}`] !== null &&
                        this.$refs[`item_${item.label}`] !== undefined
                    ) {
                        this.$set(this.positions, item.label, {
                            x: item.position.x - 50,
                            y: item.position.y - 50,
                        })
                    }
                },
            )
            window.requestAnimationFrame(() => this.stickMatterToDom())
        },
        async animatedStart() {
            this.items.forEach(item => {
                const key = item.id
                const index = this.items.findIndex(x => x.id === item.id)
                const obj = {
                    x: this.positions[key].x,
                    y: this.positions[key].y,
                }
                anime({
                    targets: obj,
                    x: this.afterStartPositions[key].x,
                    y: this.afterStartPositions[key].y,
                    duration: 1000,
                    delay: index * this.animationDelayPerObject,
                    easing: 'easeOutCubic',
                    update: () => {
                        this.$set(this.positions, key, obj)
                    },
                    complete: () => {
                        if (
                            this.zoneOneItems.includes(item.id) ||
                            this.zoneTwoItems.includes(item.id) ||
                            this.zoneThreeItems.includes(item.id)
                        ) {
                            const c = Matter.Bodies.circle(
                                obj.x + 50,
                                obj.y + 50,
                                50,
                                {
                                    label: item.id,
                                    render: {
                                        fillStyle: 'transparent',
                                    },
                                    frictionAir: 0.2,
                                },
                            )
                            Matter.Composite.add(this.matter.engine.world, [c])
                        }
                    },
                })
            })
        },

        setDropZonePosition() {
            const dropZones = ['drop-zone-1', 'drop-zone-2', 'drop-zone-3']
            dropZones.forEach(zone => {
                if (this.$refs[zone]) {
                    const rect = this.$refs[zone].getBoundingClientRect()
                    const position = { x: rect.x, y: rect.y + rect.height / 2 }

                    this.$set(this.dropZoneConnector, zone, {
                        x: position.x,
                        y: position.y,
                    })
                }
            })
        },
        setItemStartPosition() {
            this.items.forEach(item => {
                let position = { x: -10, y: window.innerHeight + 10 }
                let zones = []
                if (this.zoneOneItems.includes(item.id)) {
                    zones.push('drop-zone-1')
                }
                if (this.zoneTwoItems.includes(item.id)) {
                    zones.push('drop-zone-2')
                }
                if (this.zoneThreeItems.includes(item.id)) {
                    zones.push('drop-zone-3')
                }
                if (zones.length > 0) {
                    this.$set(this.svgObjects, item.id.toString(), {
                        zones: zones,
                    })
                }
                this.$set(this.positions, item.id.toString(), {
                    x: position.x,
                    y: position.y,
                })
            })
        },
        setItemEndPosition() {
            this.items.forEach(item => {
                let position
                let zone = this.svgObjects[item.id]
                if (zone) {
                    position = this.getRandomPosInBoundingRect(
                        this.$refs['interactive-area'].getBoundingClientRect(),
                    )
                } else {
                    position = this.getRandomPosInBoundingRect(
                        this.$refs[
                            'non-interactive-area'
                        ].getBoundingClientRect(),
                    )
                }
                this.$set(this.afterStartPositions, item.id.toString(), {
                    x: position.x,
                    y: position.y,
                })
            })
        },
        dragStart(item, event) {
            this.isDragging = true
            const target = event.target
            target.classList.add('is-dragging')
            const id = findTouchId(target, event)

            if (
                this.draggedObjects !== {} ||
                !this.draggedObjects.find(
                    x => x.item === item || x.touchId === id,
                )
            ) {
                const draggedData = {
                    touchId: id,
                    item: item,
                    domObject: target,
                    rect: target.getBoundingClientRect(),
                    dragOffset: {
                        x:
                            event.touches[id].clientX -
                            target.getBoundingClientRect().x,
                        y:
                            event.touches[id].clientY -
                            target.getBoundingClientRect().y,
                    },
                    startPos: {
                        x: target.getBoundingClientRect().x,
                        y: target.getBoundingClientRect().y,
                    },
                }
                this.$set(this.draggedObjects, id, draggedData)
            }
        },
        dragUpdate(event) {
            if (!this.draggedObjects === {}) {
                return
            }

            Object.keys(this.draggedObjects).forEach(key => {
                const position = {
                    x:
                        event.touches[key].clientX -
                        this.draggedObjects[key].dragOffset.x,
                    y:
                        event.touches[key].clientY -
                        this.draggedObjects[key].dragOffset.y,
                }
                this.$set(
                    this.positions,
                    this.draggedObjects[key].item.id,
                    position,
                )
            })
        },
        isEnabled(zone) {
            if (!this.isDragging) return true
            switch (zone) {
                case 'drop-zone-1':
                    return this.zoneOneItems.includes(
                        this.matter.nowDraggingItem,
                    )
                case 'drop-zone-2':
                    return this.zoneTwoItems.includes(
                        this.matter.nowDraggingItem,
                    )
                case 'drop-zone-3':
                    return this.zoneThreeItems.includes(
                        this.matter.nowDraggingItem,
                    )
            }
        },
        getItemStyle(item) {
            const position = this.positions[item.id]
            let isDragged = Object.values(this.draggedObjects)
                .map(obj => obj.item.id)
                .includes(item.id)

            if (isDragged) {
                return {
                    position: 'absolute',
                    left: position.x + 'px',
                    top: position.y + 'px',
                    zIndex: 2,
                }
            }
            return {
                position: 'absolute',
                left: position.x + 'px',
                top: position.y + 'px',
                zIndex: 1,
            }
        },
        getRandomPosInBoundingRect(boundingRect) {
            const xPos = this.getRandomFloat(
                boundingRect.left,
                boundingRect.width + boundingRect.left,
            )
            const yPos = this.getRandomFloat(
                boundingRect.top,
                boundingRect.height + boundingRect.top,
            )
            return { x: xPos, y: yPos }
        },
        getRandomFloat(min, max) {
            return parseFloat((Math.random() * (max - min) + min).toFixed(2))
        },
        async thumbnailUrl(item) {
            if (
                !item.thumbnailUrl &&
                item.content?.content?.manifest_url?.global
            ) {
                await fetch(item.content.content.manifest_url.global)
                    .then(response => response.json())
                    .then(data => {
                        item.thumbnailUrl = data.thumbnail['@id']
                    })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.matter {
    position: absolute;
    left: 0;
    top: 40%;
    width: 50%;
    height: 50%;
}

.item {
    //transition: all 1s linear;

    &.is-dragging {
        pointer-events: none;
        transition: all 0s;
    }

    overflow: hidden;

    & img {
        transform: scale(1.2);
        object-fit: cover;
    }

    &.disabled {
        pointer-events: none;

        img {
            filter: saturate(0);
        }
    }
}

[id^='drop-zone'] {
    position: absolute;
    right: 2%;
    width: 20%;
    height: 25%;
    overflow: hidden;

    & > * {
        pointer-events: none;
    }

    &[id$='1'] {
        top: 10%;
    }

    &[id$='2'] {
        transform: translateY(-50%);
        top: 50%;
    }

    &[id$='3'] {
        bottom: 10%;
    }

    div {
        pointer-events: none;
    }

    &.disabled {
        opacity: 0.3;
    }
}

.interactive-area {
    position: absolute;
    top: 10vh;
    left: calc(40vw);
    height: calc(85vh - (100vw * var(--pxToVw)));
    width: calc(25vw);
}

.non-interactive-area {
    position: absolute;
    top: 75vh;
    left: calc(50vw * var(--pxToVw));
    height: calc(25vh - (150vw * var(--pxToVw)));
    width: calc(20vh - (50vw * var(--pxToVw)));
}
</style>
