var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 w-100",on:{"touchmove":function($event){$event.preventDefault();$event.stopPropagation();return _vm.dragUpdate.apply(null, arguments)}}},[(!_vm.itemsAvailable)?_c('no-items-modal',{attrs:{"language":_vm.$i18n.locale,"unit":_vm.$route.name}}):[(_vm.refsAvailable && _vm.showSvgLines)?_c('div',{staticClass:"pointer-events-none h-100 w-100"},[_vm._l((_vm.items.filter(x => _vm.svgObjects[x.id])),function(item){return _vm._l((_vm.svgObjects[item.id].zones),function(zone){return _c('svg-drawer',{key:'zone' +
                            item.id +
                            _vm.positions[item.id].x +
                            _vm.positions[item.id].y +
                            zone,staticClass:"svg-line",attrs:{"start":{
                        x: _vm.positions[item.id].x + 50,
                        y: _vm.positions[item.id].y + 50,
                    },"end":{
                        x: _vm.dropZoneConnector[zone].x,
                        y: _vm.dropZoneConnector[zone].y,
                    }}})})})],2):_vm._e(),_c('canvas',{ref:"matterCanvas",staticStyle:{"width":"100%","height":"100%","position":"absolute","top":"0","z-index":"2"},attrs:{"id":"canvas-to-render-to"}}),(_vm.refsAvailable)?_c('div',{ref:"loadoutContainer",staticClass:"collection",staticStyle:{"width":"100vw","height":"100vh","position":"absolute","top":"0"}},_vm._l((_vm.items),function(item){return _c('div',{key:'drag_item_' + item.id,ref:`item_${item.id}`,refInFor:true,staticClass:"item",class:[
                    item.thumbnailUrl ? 'circle-image' : '',
                    !(
                        _vm.zoneOneItems.includes(item.id) ||
                        _vm.zoneTwoItems.includes(item.id) ||
                        _vm.zoneThreeItems.includes(item.id)
                    )
                        ? 'disabled'
                        : '',
                ],style:(_vm.getItemStyle(item)),on:{"touchstart":function($event){$event.preventDefault();$event.stopPropagation();return _vm.dragStart(item, $event)}}},[(item.thumbnailUrl)?_c('img',{staticClass:"pointer-events-none",attrs:{"src":item.thumbnailUrl,"width":"100px","height":"100px"}}):_vm._e()])}),0):_vm._e(),_c('div',{ref:"non-interactive-area",staticClass:"non-interactive-area"}),_c('div',{ref:"interactive-area",staticClass:"interactive-area"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.zoneOneItems.length >= 1),expression:"zoneOneItems.length >= 1"}],ref:"drop-zone-1",staticClass:"drop-zone",class:_vm.isEnabled('drop-zone-1') ? 'enabled' : 'disabled',attrs:{"id":"drop-zone-1"}},[_vm._t("dropZone1")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.zoneTwoItems.length >= 1),expression:"zoneTwoItems.length >= 1"}],ref:"drop-zone-2",staticClass:"drop-zone",class:_vm.isEnabled('drop-zone-2') ? 'enabled' : 'disabled',attrs:{"id":"drop-zone-2"}},[_vm._t("dropZone2")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.zoneThreeItems.length >= 1),expression:"zoneThreeItems.length >= 1"}],ref:"drop-zone-3",staticClass:"drop-zone",class:_vm.isEnabled('drop-zone-3') ? 'enabled' : 'disabled',attrs:{"id":"drop-zone-3"}},[_vm._t("dropZone3")],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }