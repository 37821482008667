<template>
    <svg
        class="position-absolute"
        :viewBox="'0 0 ' + width + ' ' + height"
        xmlns="http://www.w3.org/2000/svg"
    >
        <line
            class="svg-line"
            stroke-width="2"
            :x1="start.x"
            :x2="end.x"
            :y1="start.y"
            :y2="end.y"
        />
    </svg>
</template>

<script>
export default {
    name: 'SvgDrawer',
    props: {
        width: {
            type: Number,
            default: 1920,
        },
        height: {
            type: Number,
            default: 1080,
        },
        start: {
            type: Object,
            default: () => {},
        },
        end: {
            type: Object,
            default: () => {},
        },
    },
}
</script>

<style lang="scss" scoped>
svg {
    top: 0;
}

.svg-line {
    stroke: var(--secondary);
}
</style>
